@import url("https://use.typekit.net/pxl0tjj.css");


//MEDIA BREAKPOINTS
$sm:576px;
$md:768px;
$lg:992px;
$xl:1200px;
$xxl:1600px;

$content-width:1600px;
$content-padding: 15px;
$content-margin-padding: calc(((100vw - #{$content-width}) / 2));
$negative-content-margin: calc((#{$content-margin-padding} * -1) + 10px);


$header-height: 140px;
$header-height-xs: 80px;


$font-size-h1: 100px;
$font-size-h2: 64px;
$font-size-h2-modal: 52px;
$font-size-h3: 30px;

$font-size-nav-items: 16px;
$font-size-main: 18px;

$button-height: 50px;

// MOBILE

$font-size-h1-sm: 70px;
$font-size-h2-sm: 50px;
$font-size-h3-sm: 26px;