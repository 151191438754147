

$bg-blue-light: #EFF4F7;
$bg-blue-dark:#DAE3E8;
$cyan-dark: #3BACBE;
$cyan-light: #35B9CE;
$lime: #CCDB3F;
$red: #EE2F63;
$bg-blue-erweitert: #4e8fcc;

$main-black: #000000;
$main-black-opacity: #000000E0;
$not-black: #2F2F36;
$dark-gray: #42424A;
