.section-platform1-content {
  @include background50($cyan-dark, #fff);
  @extend .section-content;

  @include ie() {
    flex-basis: 100%;
  }

  .accent-line {
    background: $dark-gray;
  }
}

.section-platform2-content {
  @include background50(transparent, $lime);
  @extend .section-content;

  @include ie() {
    flex-basis: 100%;
  }
  .accent-line {
    background: $lime;
  }
}

.section.platform2 {
  @media screen and (max-width: 992px) {
    flex-direction: column-reverse;
  }
}

.platform-before,
.platform-now,
.technology {
  svg {
    #application1,
    #laptopPlatform, #cog2 {
      animation: bounce-6 4s ease-in-out 0s infinite;
    }

    #application2, #cog1 {
      animation: bounce-6 4s ease-in-out 1s infinite;
    }

    #application3, #cog3 {
      animation: bounce-6 4s ease-in-out 2s infinite;
    }

    #houses,
    #technologyPlatform {
      animation: bounce-1 4s ease-in-out 0s infinite;
    }

    #person3,
    #person9 {
      animation: bounce-2 0.5s ease 2s infinite;
    }

    #person4,
    #person6,
    #person2 {
      animation: bounce-2 0.8s ease 0s infinite;
    }

    #person8,
    #person1 {
      animation: bounce-2 1s ease 1s infinite;
    }
    transform-origin: 50% 50%;

    #cog2,
    #cog3,
    #cog1 {
      transform-box: fill-box;
      transform-origin: 50% 50%;
      // transform: rotateX(180deg) rotateY(0deg) rotateZ(0deg);
    }

    #smoke {
      // fill: #f00;
      transform: skew(2deg);
      animation: skew 2s linear 1s infinite;
    }
  }
}

@keyframes skew {
  0% {
    transform: skew(0deg);
  }

  25% {
    transform: skew(1deg);
  }
  50% {
    transform: skew(0deg);
  }
  75% {
    transform: skew(-1deg);
  }
  100% {
    transform: skew(0deg);
  }
}

@keyframes rotate-1 {
  0% {
    transform-box: fill-box;
    transform-origin: 50% 50%;
    transform: rotate(0deg);

    transform: rotateX(0deg) rotateY(0deg) rotateZ(-0deg);
  }

  50% {
    transform-box: fill-box;
    transform-origin: 50% 50%;
    transform: rotateX(0deg) rotateY(5deg) rotateZ(-30deg);
  }

  100% {
    transform-box: fill-box;
    transform-origin: 50% 50%;
    transform: rotateX(0deg) rotateY(10deg) rotateZ(100deg);
  }
}

@keyframes bounce-1 {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes bounce-2 {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-8px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes bounce-6 {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}
