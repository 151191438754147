@import './styles/sections/section-home';
@import './styles/sections/section-platform';
@import './styles/sections/section-references';
@import './styles/sections/section-projects';
@import './styles/sections/section-benefits';
@import './styles/sections/section-technology';
@import './styles/sections/section-roadmap';
@import './styles/sections/section-imageVideo';
@import './styles/sections/section-contact';

.section {
  display: flex;
  margin: 100px 0;

  img {
    margin: 5% 15px;
    width: 100%;
    max-width: 780px;
    align-self: center;
    flex: 0 0 auto;
    @media screen and (max-width: 1980px) {
      max-width: 40vw;
    }
  }

  .platform-before,
  .platform-now,
  .technology-platform {
    width: 100%;
    margin: 5% 15px;
    @include center-vertically;

    svg {
      flex: 0 0 auto;
      height: auto;
      max-width: 780px;

      @include ie() {
        height: 600px;
      }

      @media screen and (max-width: 1980px) {
        max-width: 40vw;
      }
    }
  }

  @media screen and (max-width: 992px) {
    flex-direction: column;

    img,
    .platform-before svg,
    .platform-now svg,
    .technology-platform svg {
      margin: 25px;
      width: calc(100% - 50px);
      max-width: 1000px;
    }
  }
}

.section-content {
  padding: 50px 5%;
  @media screen and (max-width: $content-width) {
    padding: 50px 25px;
  }
  @media screen and (max-width: 992px) {
    margin: 10px;
  }
}

.description-wrapper {
  display: flex;

  .accent-line {
    height: 4px;
    width: 100%;
    margin-top: 10px;
  }

  .description {
    margin-top: -40px;
    margin-left: 10vw;
    margin-left: 10%;

    @include ie() {
      flex-basis: 400%;
    }
  }
}



.section.platform2, .section.platform1, .section.technology {
  .fade-in-section {
    width: 100%;
  }
}
