.footer {
  @include extend-across-page;
  height: $header-height;
  background: $main-black;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: $content-width) {
    padding: 0 25px;
  }

  .footer-logo-wrapper {
    @include center-vertically;
  }

  img {
    height: 60px;
    width: auto;

    @media screen and (max-width: 576px) {
      width: 25vw;
      height: auto;
    }
  }

  .footer-links-wrapper {
    display: flex;
  }

  .footer-link {
    @include center-vertically;
    button {
      display: inline;
      padding: 0 10px;
      height: 30px;
      font-size: $font-size-nav-items;

      font-family: europa, sans-serif;

      color: $bg-blue-light;
      text-decoration: none;

      &:hover,
      &:focus,
      &:active {
        color: $lime;
      }

      @media screen and (max-width: 576px) {
        font-size: 14px;
      }
    }

    &:not(:first-child) button {
      border-left: 1px solid $bg-blue-light;
    }
  }
}

.imprint {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  overflow-y: scroll;

  .close-image-modal-btn {
    position: absolute;
    right: 25px;
    top: 25px;
    fill: $not-black;
    height: 50px;
    width: 50px;
  }

  .imprint-card {
    position: relative;
    background: #fff;
    padding: 25px;
    max-width: 800px;
    height: fit-content;
    margin: 30px 0;

    @include ie() {
      overflow-y: scroll;
    }

    h2 {
      font-size: $font-size-h2-modal;
      margin: 0;
      color: $cyan-dark;
    }

    h3 {
      margin-bottom: -10px;
    }

    h5 {
      font-size: medium;
    }

    .bmf-data-privacy {
      color: #e6310e;
      font-size: 0.9rem;
      font-weight: 600;
      margin-top: 10px;
    }
  }
}
