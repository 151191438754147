// ---------- When Modal is open, hide scrollbar and shift content to the left -------------- //
#root {
  transition: all 0.5s ease;
  &.modal-open {
    margin-left: -100%;
  }
}

body.modal-open {
  position: relative;
  width: calc(100% - 17px);
  overflow: hidden;
}

// ---------- Project Details Page / Modal -------------- //
#project-modal {
  position: relative;
  z-index: 999;
}

.project-details {
  background: $bg-blue-light;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  overflow-y: scroll;
  transition: all 0.5s ease;

  &.hide-to-right {
    right: -100%;
  }

  .navigate-btn {
    color: $not-black;

    &.btn-xl {
      display: block;
      position: absolute;
      bottom: 0;
    }

    &.btn-xs {
      display: none;
      text-align: left;
    }
  }

  .project-details-content {
    margin: auto;
    padding: 50px 0;
    max-width: $content-width;

    .pdf-file {
      display: flex;
      align-items: center;
      text-decoration: underline;

      svg {
        width: 15px;
        height: 35px;
        fill: $red;
        margin-right: 10px;
      }
    }

    @media screen and (max-width: $content-width) {
      margin: 25px;
    }

    @media screen and (max-width: 576px) {
      .project-info {
        padding: 15px;

        .project-text {
          margin-right: 0;
        }
      }

      h2 {
        font-size: 30px;
        word-break: break-word;
      }

      .accent-line {
        width: 30%;
      }

      .project-info .project-screenshots {
        img {
          width: calc(100vw - 50px - 30px);
        }
      }

      .btn-xl {
        display: none;
      }

      .btn-xs {
        display: block;
      }
    }
  }

  .project-details-header {
    display: flex;
    justify-content: space-between;

    img {
      width: 300px;
      height: auto;

      align-self: center;
      flex: 0 auto auto;

      @media screen and (max-width: 992px) {
        max-width: 50vw;
        align-self: center;
        flex: 0 auto auto;
      }
    }

    svg {
      fill: $not-black;
      width: 40px;
      height: 40px;
    }
  }

  .accent-line {
    background: $lime;
    width: 10%;
    margin-bottom: 50px;
    margin-top: -20px;
  }

  .project-info {
    display: flex;
    justify-content: space-between;
    background: #fff;
    padding: 70px;

    @media screen and (max-width: 576px) {
      display: block;
    }

    .project-screenshots {
      text-align: right;

      img {
        width: 25vw;
        height: auto;
        border: 1px solid #efefef;
        display: block;

        &:not(:last-child) {
          margin-bottom: 25px;
        }

        &:hover {
          opacity: 0.8;
          cursor: pointer;
        }
      }
    }

    .project-text {
      line-height: calc(#{$font-size-main} * 2);
      position: relative;
      margin-right: 30px;
      padding-bottom: 50px;
      font-size: $font-size-main;
    }
  }
}

// ---------- Image Modal - enlarge clicked image -------------- //
#image-modal {
  position: relative;
  z-index: 1111;
}

.image-modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .close-image-modal-btn {
    position: absolute;
    right: 25px;
    top: 25px;
    fill: #fff;
    height: 50px;
    width: 50px;
  }

  img {
    width: 90vw;

    @media screen and (max-width: 576px) {
      width: 95vw;
    }
  }
}