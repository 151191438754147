@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin center-vertically {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hide {
  visibility: hidden;
}

.display-none {
  display: none;
}

@mixin ie {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

@mixin extend-across-page { 
  


  @media screen and (min-width: $content-width) {
    margin: 0 $negative-content-margin 0 calc(#{$negative-content-margin} - 2px);
    padding: 0 $content-margin-padding;
  }

  @media screen and (min-width: 1981px) {
    margin-left: calc((((100vw - #{$content-width}) / 2) * -1) + 0px);
    margin-right: calc((((100vw - #{$content-width}) / 2) * -1) + 0px);
  }

  @include ie() {
    margin-left: calc((((100vw - #{$content-width}) / 2) * -1) + 10px);
    margin-right: calc((((100vw - #{$content-width}) / 2) * -1) + 10px);
  }

  
 
  @media screen and (max-width: $content-width) {
    margin: 0 !important;
  }
}

@mixin background50($color1, $color2) {
  background: linear-gradient(
    to right,
    $color1 0%,
    $color1 50%,
    $color2 50%,
    $color2 100%
  );
}
@mixin background25($color1, $color2) {
  background: linear-gradient(
    to right,
    $color1 0%,
    $color1 25%,
    $color2 25%,
    $color2 50%
  );
}

@mixin btn-background($color) {
  position: relative;
  z-index: 1;

  background-image: linear-gradient(
    to right,
    transparent 0%,
    transparent 70%,
    $color 70%,
    $color 100%
  );

  &::before {
    width: 30%;
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(to right, $color 0%, $color 100%);
    z-index: -1;
    transition: width 0.2s linear;
  }

  &:hover,
  &:active,
  &:focus {
    &::before {
      width: 100%;
    }
  }
}
