@import "./styles/branding";
@import "./styles/variables";
@import "./styles/mixins";
@import "./styles/buttons";

@import "./styles/header";
@import "./styles/sections/section";
@import "./styles/project-details";
@import "./styles/iconCards";
@import "./styles/footer";

body,
#root {
  overflow-x: hidden;
  font-family: source-sans-pro, sans-serif;
  -webkit-overflow-scrolling: touch;
}

h1,
h2,
h3 {
  font-family: europa, sans-serif;
  font-style: normal;
  font-weight: 700;
  color: $not-black;
}

h1 {
  font-size: $font-size-h1;
  margin-top: 0;
}

h2 {
  font-size: $font-size-h2;
}

h3 {
  font-size: $font-size-h3;
}

h4 {
  font-size: $font-size-main;
  margin-bottom: 5px;
}

a {
  color: $not-black;

  &:hover {
    color: $cyan-light;
  }
}

@media screen and (max-width: 992px) {
  h1 {
    font-size: $font-size-h1-sm;
  }

  h2 {
    font-size: $font-size-h2-sm;
  }

  h3 {
    font-size: $font-size-h3-sm;
  }
}

.main {
  max-width: $content-width;
  margin: auto;

  margin-top: calc(#{$header-height});
  p {
    font-size: $font-size-main;
  }
}

@media screen and (max-width: $content-width) {
  .main {
    //  margin: 0 $content-padding;
  }
}

@media screen and (min-width: 1981px) {
  .section.home,
  .section.contact,
  .section.platform1,
  .section.platform2 {
    margin-right: -5vw;
    margin-left: -5vw;
  }
}

.fade-in-section {
  opacity: 0;
  transform: translateY(10vh);
  // transform: none;
  flex: 1 1 auto;
  // visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;

  @include ie() {
    flex-basis: auto;
    transform: none;
    // transform: translateY(0);
  }

  &.is-visible {
    opacity: 1;
    transform: none;
    // visibility: visible;
  }
}

/* Set default durations */
:root {
  --animation-duration: 250ms;
  --transition-duration: 250ms;
}

/* Contextually shorten duration length */
@media screen and (prefers-reduced-motion: reduce), (update: slow) {
  :root {
    --animation-duration: 0.001ms !important;
    --transition-duration: 0.001ms !important;
  }
}

@media screen and (prefers-reduced-motion: reduce), (update: slow) {
  /* Remove duration for all unknown animation when a user requests a reduced animation experience */
  * {
    animation-duration: var(--animation-duration);
    animation-iteration-count: 1 !important;
    transition-duration: var(--animation-duration);
  }
}

/* Update the duration when animation is critical to understanding and the device can support it */
@media screen and (prefers-reduced-motion: reduce), (update: fast) {
  .c-educational-concept {
    /* Set a new animation duration scoped to this component */
    --animation-duration: 6000ms !important;

    animation-name: educational-concept;
    /* Use the scoped animation duration */
    animation-duration: var(--animation-duration);
  }
}