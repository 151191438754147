.section-benefit1-content {
  @extend .section-content;

  .description-wrapper 
    .accent-line {
      background: $lime;
      width: 30%;
      max-width: 200px;
    }
}

.section-benefit2-content,
.section-benefit3-content {
  @extend .section-content;
  @include center-vertically;

  @media screen and (min-width: 992px) {
    width: 50%;
  }

  @include ie() {
    flex-basis: 100%;
  }
}

.section.benefit2 {
  @media screen and (max-width: 992px) {
    flex-direction: column-reverse;
  }
}

.benefit3,
.benefit2 {
  @include ie() {
    height: 500px;
  }
  img {
    height: 100px;
  }
}

.benefits {
  position: relative;
}


.benefit-icon-wrapper {
  @include center;
  align-items: flex-start;
  width: 40vw;
  // position: relative;

  @include ie() {
    flex-basis: 100%;
    height: 300px;
  }

  .benefit-icon {
    margin-top: 40px;
    width: 12vw;
    height: 12vw;

    svg {
      max-height: 300px;
    }

    @include center;

    &.lime {
      background: $lime;
      border-radius: 50%;
      svg {
        fill: #fff;
      }
    }

    &.cyan {
      background: $cyan-dark;
      border-radius: 50%;
      svg {
        fill: #fff;
      }
    }

    svg {
      width: 50%;
    }
  }

  @media screen and (max-width: 992px) {
    width: 100%;
    margin: -20px 0 100px 0;
    height: 20vw;

    .benefit-icon {
      width: 40vw;
      height: 40vw;
    }
  }

  
  @media screen and (max-width: 576px) {
    .benefit-icon {
      width: 50vw;
      height: 50vw;
    }
  }
}

.benefit1 {
  @media screen and (min-width: 992px) {
    .section-content {
      width: 100vw;
    }
  }
}



