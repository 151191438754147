.logos-wrapper {
  background: $not-black;
  margin-right: $negative-content-margin;

  @include ie() {
    margin-right: calc((((100vw - #{$content-width}) / 2) * -1) + 10px);
  }

  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  padding: 30px 0;

  .reference-logo {
    height: 80px;
    width: auto;
    padding: 20px 60px;
  }

  @media screen and (max-width: $content-width) {
    margin-right: 0;

    .reference-logo {
      height: 60px;
      width: auto;
      padding: 20px;
    }
  }
}

.quote-wrapper {
  @include center;
  margin-top: 50px;
}

.quote {
  width: 70%;
  overflow-x: hidden;
  display: flex;

  .quote-icon {
    font-size: 300px;
    color: $bg-blue-dark;
    margin-right: 50px;
    margin-top: -80px;
  }

  .testimonials {
    display: flex;
    overflow-x: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .quote-content {
    transition: all 0.5s ease;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center !important;

    @include ie() {
      h3, p {
          width: 800px;
        
      }
    }

    word-break: break-word;
  }

  .quote-portrait {
    $img-size: 90px;
    height: $img-size;
    min-width: $img-size;
    max-width: $img-size;
    border-radius: 50%;
    margin-left: 40px;
    overflow: hidden;

    img {
      width: auto;
      height: $img-size;
      transform: translateX(45px) translateX(-50%);
    }
  }

  .description-wrapper {
    width: 50%;

    &.has-image {
      width: 70%;
    }

    .accent-line {
      width: 20%;
      background: $lime;
    }

    .description {
      margin-top: -25px;

      a {
          color: $dark-gray;

          &:hover {
            color: lighten($dark-gray, 10);
          }
      }
    }

    .credit {
      font-size: 0.8rem;
    }
  }


  
  @media screen and (max-width: $content-width) {
    .quote-content {

      h3, p, .description-wrapper {
          width: calc(70vw - 200px);
        
      }
    }
  }

  @media screen and (max-width: 992px) {
    width: 90%;

    .quote-content {
      // width: calc(100vw - 250px);
      word-wrap: break-word;

      h3, p, .description-wrapper {
          width: calc(100vw - 250px);
        
      }
    }

    .quote-icon {
      font-size: 250px;
      margin-right: 30px;
      margin-top: -50px;
    }

    .description-wrapper {
      width: 100%;
    }
  }

  
  @media screen and (max-width: 768px) {

    h3 {
      font-size: 1.4rem;
    }

    .description-wrapper {
      flex-wrap: wrap;

      .description {
        margin: 20px 0;
      }
    }
  }

  @media screen and (max-width: 576px) {
    width: 100%;
    padding: 0 15px;

    .quote-content {
      // width: calc(100vw - 120px);
      word-wrap: break-word;

      h3, p, .description-wrapper {
        width: calc(100vw - 120px);
      
    }

      p {
        font-size: 1rem;
      }
    }

    .description-wrapper {
      width: 100%;
    }

    .quote-icon {
      font-size: 150px;
      margin-right: 15px;
      margin-top: 0px;
    }
  }
}

.quote-slider {
  float: right;

  @include ie() {
    float: left;
  }

  @media screen and (max-width: $content-width) {
    float: left;
  }

  @media screen and (max-width: 992px) {
    float: left;
  }

  @media screen and (max-width: 576px) {
    margin-right: 25px;
  }

  .quote-btn {
    height: 35px;
    width: 20px;
    padding: 0;
    background: $not-black;
    margin: 7px;
    transition: background-color 0.3s ease;

    &.active {
      background: $lime;
    }
  }
}
