header {
  padding: 0 75px;
  background: #fff;
  border-bottom: 1px solid transparent;

  height: $header-height;
  width: calc(100% - 75px - 75px);
  position: fixed;
  z-index: 2;
  top: 0;

  transition: all 0.3s;
  transition-property: top, border-color;

  @include center-vertically;

  @media screen and (max-width: 576px) {
    height: $header-height-xs;
  }

  &.border-bottom {
    border-color: $bg-blue-dark;
  }

  &.hide-to-top {
    top: -$header-height;
    @media screen and (max-width: 576px) {
      top: -$header-height-xs;
    }
  }

  .header-content-wrapper {
    display: flex;
    justify-content: space-between;

    .logo {
      height: 60px;
      width: auto;
    }

    .logo-sm {
      height: 30px;
    }

    .navigation-items {
      @include center-vertically;
      margin: 0 20px;
      max-height: $header-height;
      overflow-y: hidden;

      div {
        text-align: center;
      }

      li {
        display: inline-block;

        &.current-section a {
          background: $lime;
          font-weight: 700;
        }
      }

      a {
        font-size: $font-size-nav-items;
        color: $not-black;
        text-decoration: none;
        padding: 0px 20px;
        height: $button-height;
        display: inline-block;

        @include center-vertically;

        @media screen and (min-width: 1980px) {
          font-size: 22px;
        }
      }
    }

    .centering-wrapper {
      @include center-vertically;
    }

    .power-bi-demo-btn {
      //UNCOMMENT ONCE THERE IS A DEMO
      // visibility: hidden;
      min-width: 122px;
    }
  }
}

.power-bi-link-wrapper {
  padding: 0 40px;
  margin-top: -30px;
  text-align: center;

  .power-bi-demo-btn {
    display: inline-block;
  }
}

.mobile-menu-btn,
.mobile-menu {
  display: none;
}

@media screen and (max-width: 1000px) {
  header {
    padding: 0 25px;
    width: calc(100% - 50px);
  }
}

@media screen and (max-width: 768px) {
  header {
    padding: 0 15px;
    width: calc(100% - 30px);

    &.fixed {
      position: fixed;
      z-index: 20;
    }

    .header-content-wrapper {
      .power-bi-demo-btn {
        display: none;
      }
    }

    .header-content-wrapper .navigation-items li {
      display: none;
    }
  }

  .mobile-menu-btn {
    display: block;
    fill: $not-black;
    width: 60px;
    height: 60px;
    transition: all 0.5s ease;

    &:hover {
      fill: $lime;
    }
  }

  .mobile-menu {
    display: block;
    transition: all 0.4s ease;
    z-index: 1;
    width: 100vw;
    max-width: 500px;
    height: calc(100vh - #{$header-height} - 1px);
    position: fixed;

    background: #fff;
    border-left: 1px solid $bg-blue-dark;
    right: 0;
    top: calc(#{$header-height} + 1px);

    &.hidden {
      transform: translateX(100%);
    }

    ul {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      list-style-type: none;
      height: calc(90vh - #{$header-height} - 1px);
      padding: 0 40px;
    }

    .current-section a {
      background: $lime;
      font-weight: 700;
    }

    a {
      font-size: $font-size-main;
      color: $not-black;
      text-decoration: none;
      padding: 0px 20px;
      height: $button-height;
      display: inline-block;

      @include center-vertically;

      &:focus,
      &:active {
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .mobile-menu {
    height: calc(100vh - #{$header-height-xs} - 1px);
    top: calc(#{$header-height-xs} + 1px);

    ul {
      height: calc(90vh - #{$header-height-xs} - 1px);
    }
  }
}

.d-none {
  display:none;
}
.d-hide-span {
  padding: 20 0 0 0;
  span {
    display: none;
  }
}

.d-history {
  
  
  width: 100%;
  text-align: center;
  margin: auto;
  
  div {
    display: flex;
    span {
      display: block;
      font-size: 28px !important;
      font-weight: normal;
      padding-top:20px;
    }
  }

  svg {
    margin: 0 0 0 20px;
    display: block;
    width: 40px;
    height: 40px;
  }
  

}
