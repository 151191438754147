.roadmap {
  @extend .section-content;

  // .section-roadmap-content {
  //  @include ie() {
  //    flex: 1 1 auto;
  //  }
  // }
  
  .description-wrapper {
    .accent-line {
      background: $lime;
      width: 30%;
      max-width: 100px;
    }
    .description {
      max-width: 70vw;
      margin-left: 5%;
      margin-top: -20px;
    }
  }
}

.timeline-wrapper {
  // <-- remove for vertical
  //   overflow-x: scroll;

  //   -ms-overflow-style: none;
  //   &::-webkit-scrollbar {
  //     display: none;
  //   }
  // -->
}

.vertical-timeline {
  padding: 0 !important;

  // <-- remove for vertical
  //   padding: 150px 0 !important;
  //   left: 50%;
  //   display: flex !important;

  //   .vertical-timeline-element:nth-child(odd) {
  //     left: -50%;

  //     .vertical-timeline-element-content {
  //       top: calc(50% + 50px);
  //       width: 350px;
  //       height: 230px;
  //       left: calc(50% - 6px);
  //     }

  //     .vertical-timeline-element-content-arrow {
  //       left: calc(50% - 9px) !important;
  //       left: -3px !important;
  //       top: -15px !important;
  //       transform: rotate(90deg) !important;
  //     }
  //   }

  //   .vertical-timeline-element:nth-child(even) {
  //     left: -50%;

  //     .vertical-timeline-element-content {
  //       top: calc(-50% - 50px);
  //       width: 350px;
  //       height: 230px;
  //       left: calc(50% - 6px);
  //     }

  //     .vertical-timeline-element-content-arrow {
  //       //   left: calc(50% - 9px) !important;
  //       left: -2px !important;
  //       top: calc(100% + 2px) !important;
  //       transform: rotate(270deg) !important;
  //     }
  //   }

  //   .vertical-timeline-element-icon {
  //     top: calc(50% - 15px);
  //   }
  // -->
}

.vertical-timeline.vertical-timeline--two-columns {
  // <-- remove for vertical
  //   max-width: $content-width;
  //   margin: unset;

  //   @media screen and (max-width: $content-width) {
  //     width: 95% !important;
  //   }
  // -->
}

.vertical-timeline::before {
  background: $not-black !important;
  // <-- remove for vertical
  //   width: 100% !important;
  //   height: 4px !important;
  //   top: 50% !important;
  //   left: -50% !important;
  // -->
}

.vertical-timeline--animate .vertical-timeline-element-icon {
  background: $not-black;
  width: 30px !important;
  height: 30px !important;
  margin-left: -15px !important;
  box-shadow: none;

  @media screen and (max-width: 1170px) {
    margin-left: 5px !important;
  }
}

.vertical-timeline--animate .vertical-timeline-element-content {
  box-shadow: none;
  border-radius: 0;
}

.vertical-timeline-element-content-arrow {
  top: 8px !important;
}

.vertical-timeline-element-title {
  font-weight: 600;
  font-size: $font-size-nav-items;
}

.vertical-timeline-element-1 {
  .vertical-timeline-element-content,
  .vertical-timeline-element-icon {
    background: $bg-blue-dark;
  }

  .vertical-timeline-element-content-arrow {
    border-right: 12px solid $bg-blue-dark !important;
  }
}

.vertical-timeline-element-2 {
  .vertical-timeline-element-content,
  .vertical-timeline-element-icon {
    background: $lime;
  }

  .vertical-timeline-element-content-arrow {
    border-right: 12px solid $lime !important;
  }
}

.vertical-timeline-element-3 {
  .vertical-timeline-element-content,
  .vertical-timeline-element-icon {
    background: $cyan-dark;
  }

  .vertical-timeline-element-content-arrow {
    border-right: 12px solid $cyan-dark !important;
  }
}

.vertical-timeline-element-4 {
  .vertical-timeline-element-content,
  .vertical-timeline-element-icon {
    background: $bg-blue-erweitert;
  }

  .vertical-timeline-element-content-arrow {
    border-right: 12px solid $bg-blue-erweitert !important;
  }
}


.vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
  @media screen and (min-width: 1170px) {
    transform: translateX(-160px);
    // left: 6% !important;

    &.date-long {
      transform: translateX(-600px);
      
    }
  }
}

.vertical-timeline--animate .vertical-timeline-element:first-child {
  .vertical-timeline-element-content {
    padding: 0 !important;
    // width: 0px;
 

    @media screen and (min-width: 1170px) {
      left: 10% !important;
    }
  }
  // <-- remove for vertical
  //   .vertical-timeline-element-icon {
  //     top: calc(50% + 15px);
  //     left: 0;
  //   }
  // -->

  .vertical-timeline-element-content .vertical-timeline-element-date {
    // left: -50%;
    // top: 50%;
  }
}



.vertical-timeline--animate .vertical-timeline-element--no-children:last-child {
  // <-- remove for vertical
  //   .vertical-timeline-element-icon {
  //     top: calc(50% - 45px);
  //   }
  // -->

  .vertical-timeline-element-content {
    padding: 0 !important;
    width: 0;
  }
}
// <-- remove for vertical
// .vertical-timeline--two-columns
//   .vertical-timeline-element-content
//   .vertical-timeline-element-date {
//   width: 200px !important;
//   top: 20px !important;
// }
// -->

.timeline {
  .timeline-date {
    padding: 0 !important;
    opacity: 1 !important;
    background: transparent !important;
    color: $not-black;
    // font-weight: 600 !important;
    font-size: 28px !important;
  }

  .timeline-date-right {
    margin-top: 10px;
    float: right;
    font-weight: 600;
  }
}


@media screen and (min-width: $xl) {

  .vertical-timeline-element {
    margin: 2em 0 !important;
  }
}