.section-imageVideo-content {
    // @include background50($bg-blue-light, transparent);
  
    @extend .section-content;

    position: relative;

    .imageVideo-accent {
        top: 0;
        z-index: -1;
        position: absolute;
        left: 0%;
        height: 100%;
        width: 25%;
        border: 5px solid $bg-blue-light;
      
        @media screen and (max-width: 992px) {
          width: calc(50% - 14px);
          left: 7px;
        }
      }
      
    
.video-embed-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  
    .video-embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: 4px 4px 10px rgba(#ccc, 0.5);
    }
  }
  
}