.technology-cards-wrapper {
  display: flex;
  padding: 0 calc(5% - 20px);

  .technology-cards-1 {
    margin-top: -100px;

    .hide-cards {
        display: none;
    }
  }

  .technology-cards-2 {
    margin-top: 0px;
  }

  .technology-cards-3 {
    margin-top: -100px;
  }

  .technology-cards-4 {
    margin-top: -0px;
  }

  .technology-card {
    margin: 40px 20px;
    min-width: 250px;
    max-width: 280px;
    //   height: 300px;
    padding: 40px;
    background: $bg-blue-light;
    border-bottom-right-radius: 40px;

    h3 {
      font-weight: 400;
      font-size: 28px;
      font-family: source-sans-pro, sans-serif;
    }

    svg {
      height: 60px;
    }

    &.card-1 svg {
      fill: $not-black;
    }
    &.card-2 svg {
      fill: $cyan-light;
    }
    &.card-3 svg {
      fill: $lime;
    }
    &.card-4 svg {
      fill: $red;
    }
  }

  @media screen and (max-width: 1200px) {
    flex-wrap: wrap;

    .technology-cards-1 {
      display: flex;
      margin-top: -100px;
      flex-wrap: wrap;
      justify-content: space-between;
      .hide-cards {
          display: block;
      }
  }


    .technology-card {
      padding: 20px;
      max-width: 260px;
    }   
    
    .technology-cards-1 {
      justify-content: space-around;
  }

  }

  @media screen and (max-width: 992px) {
    max-width: 100vw;
    flex-wrap: nowrap;
    padding: 0 0;

    .technology-card {
      padding: 40px;
    }

    .technology-cards-2,
    .technology-cards-3,
    .technology-cards-4 {
      margin-top: 80px;
    }
  }

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    .technology-cards-2,
    .technology-cards-3,
    .technology-cards-4 {
      margin-top: -40px;
    }

    .technology-card {
      height: auto;
    }
  }
}
