.section.projects {
  margin-bottom: 0;
}

.section-projects-content {
  @extend .section-content;

  @include ie() {
    flex-basis: 100%;
  }

  .accent-line {
    background: $lime;
    width: 10% !important;
  }

  .description {
    margin-left: 50px !important;
    display: flex;
    margin-top: -20px !important;

    .btn-wrapper {
      text-align: center;
      .power-bi-demo-btn {
        padding: 0 12px;
      }
    }
  }

  @media screen and (max-width: 992px) {
    flex-basis: auto;

    .accent-line {
      display: none;
    }

    .description {
      display: block;
      margin-left: 0 !important;
    }
  }
}

.power-bi-iframe {
  min-width: 30vw;
  height: calc(30vw * 0.6);
  border: 1px solid $not-black;
  margin: 10px 0;
  margin-left: 30px;

  @media screen and (max-width: 992px) {
    width: 80vw;
    height: calc(80vw * 0.6);
    margin-left: 0;
    margin-top: 30px;
  }
}

.projects-lower-section {
  background: linear-gradient(
    transparent 0%,
    transparent 50%,
    $not-black 50%,
    $not-black 100%
  );

  @include extend-across-page;
}

.project-cards-wrapper {
  display: flex;
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  @media screen and (min-width: $content-width) {
    margin: 0 calc((((100vw - #{$content-width}) / 2) * -1) + 0px);
    padding: 0 $content-margin-padding;
  }

  .cards-wrapper-padding {
    height: 50px;
    min-width: 20px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.project-card {
  margin: 40px;
  min-width: 340px;
  height: 450px;
  padding: 40px;
  border-bottom-right-radius: 40px;

  background: linear-gradient(
    to top,
    $bg-blue-light,
    $bg-blue-light 50%,
    $lime 50%,
    $lime 100%
  );

  background-size: 100% 210%;
  background-position: bottom;
  position: relative;

  img {
    width: 200px;
  }

  @media screen and (max-width: 576px) {
    min-width: 250px;
    margin: 25px;
    height: 500px;
  }

  &:hover {
    // background: $lime;
    cursor: pointer;
    -webkit-animation: fill-up 0.5s ease-in-out forwards;
    -moz-animation: fill-up 2s ease-in-out forwards;
    animation: fill-up 0.5s ease-in-out forwards;
    svg path {
      fill: #fff;
      stroke: #fff !important;
    }
  }
}

@-webkit-keyframes fill-up {
  0% {
    background-position: bottom;
  }
  100% {
    background-position: top;
  }
}

@-moz-keyframes fill-up {
  0% {
    background-position: bottom;
  }
  100% {
    background-position: top;
  }
}

@keyframes fill-up {
  0% {
    background-position: bottom;
  }
  100% {
    background-position: top;
  }
}
.read-more-btn {
  position: absolute;
  bottom: 40px;
  right: 40px;
  font-size: 18px;

  svg {
    margin-bottom: -2px;
    margin-left: 10px;
  }

  @media screen and (max-width: 576px) {
    // right: 0;
    font-size: 1rem;

    .weiter {
      display: none;
    }

    .zur {
      text-transform: capitalize;
    }

    svg {
      margin-bottom: 0;
      margin-top: 20px;
      height: 15px;
    }
  }
}

.navigate-btn-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 20px 40px;

  @media screen and (max-width: 576px) {
    padding: 20px;
  }

  .navigate-btn {
    color: #fff;
  }
}

.navigate-btn {
  font-size: 18px;
  font-weight: 500;

  svg {
    margin-bottom: -2px;
    margin: 0 10px;
  }
}
