.section-contact-content {
  @include background50(transparent, $lime);

  @extend .section-content;

  @media screen and (min-width: 992px) {
    &.section-content {
      padding-bottom: 30vh !important;
    }
  }

  .description-wrapper {
    .accent-line {
      background: $lime;
      width: 30%;
    }
    .description {
      margin-top: -20px;
    }
  }
}

.section.contact {
  overflow-y: hidden;
  margin-bottom: 0;
  @media screen and (max-width: 992px) {
    flex-direction: column-reverse;
  }
}

.contact-info-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (max-width: 992px) {
    display: block;
  }
  .contact-info-item {
    min-width: 110px;
    margin: 15px 8px 15px 0;
  }

  .contact-heading {
    font-weight: 600;
  }
}

.map-container {
  @include center-vertically;

  .leaflet-container {
    width: 40vw;
    height: 70%;
    z-index: 50;
  }
  // iframe {
  //   width: 40vw;
  //   height: 70%;
  //   margin: 30px 20px;
  //   border: none;

  //   @media screen and (min-width: 1980px) {
  //     max-width: 1000px;
  //   }

  //   @media screen and (max-width: 992px) {
  //     width: calc(100% - 40px);
  //     height: 400px;
  //   }
  // }
}
