.section-home-content {
  @include background50(transparent, $bg-blue-light);

  @extend .section-content;

  .accent-line {
    background: $lime;
  }

  .test-btns-wrapper {
    //UNCOMMENT ONCE THERE IS A DEMO
    display: none;
    margin-top: 40px;
    button:first-child {
      margin-right: 10px;
    }
  }
}

.section.home {
  @media screen and (max-width: 992px) {
    margin-right: 10px;
  }

  .section-content {
    margin-left: -10vw;
    @media screen and (min-width: 1981px) {
    margin-left: 0;
    }

    @media screen and (max-width: 992px) {
      margin-left: 0;
      // background: none;
    }
  }

  .video-wrapper {
    @include center-vertically;
    margin-top: -100px;

    @media screen and (min-width: 1981px) {
      margin-top: 0;
      video {
        max-width: 1080px;
        height: auto;
      }
    }
  }

  img,
  .video-wrapper {
    z-index: -1;
    max-width: 55vw;

    @media screen and (min-width: 1981px) {
      // margin-left: -10vw;

      // max-width: 1800px;
    }

    @media screen and (max-width: 992px) {
      width: calc(100% - 0px);
      max-width: none;
      margin: 0px;

      video {
        height: auto;
        height: calc((100vw - 27px) * 0.5625);
      }
    }
  }
}
