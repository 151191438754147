.section-technology-content {
  @extend .section-content;

    @include ie() {
      flex-basis: 100%;
    
  }
  // @include background50(#fff, $bg-blue-light);
  padding-bottom: 200px !important;
  .description-wrapper {
    .accent-line {
      background: $lime;
      width: 30%;
      max-width: 100px;
    }
    .description {
      max-width: 70vw;
      margin-left: 5%;
      margin-top: -20px;
    }
  }

  
  @media screen and (max-width: 992px) { 
    padding-bottom: 0px !important;
  }
}

.section.technology {
  @media screen and (max-width: 992px) {
    flex-direction: column-reverse;
  }
}

.technology-accent {
  top: 0;
  z-index: -1;
  position: absolute;
  right: 0%;
  height: 100%;
  width: 25%;
  border: 5px solid $bg-blue-light;

  @media screen and (max-width: 992px) {
    width: calc(50% - 14px);
    left: 7px;
  }
}


.technology {
  
  position: relative;
}

.test-demo-section-wrapper {
  background: $not-black;
  color: #fff;
  position: relative;
  zoom: 1;
  margin-left: calc(#{$negative-content-margin} - 2px);
  padding: 40px;
  margin-bottom: 100px;
  @include center;

  //UNCOMMENT ONCE THERE IS A DEMO
  display: none;

  @include ie() {
    margin-left: calc((((100vw - #{$content-width}) / 2) * -1) + 10px);
  }

  @media screen and (max-width: $content-width) {
    margin-left: 0;
  }

  .test-demo-section {
    max-width: 1000px;
    width: 100%;
  }

  h3,
  button {
    color: #fff;
  }
  .description-wrapper {
    .accent-line {
      background: $lime;
      width: 25%;
    }

    .description {
      margin-top: -20px;
    }
  }
}
