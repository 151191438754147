button {
  font-weight: 700;
  background: none;
  border: none;
  padding: 0 12px;
  height: $button-height;
  line-height: 0px;

  &:hover,
  &:focus,
  &:active {
    outline: none;
    cursor: pointer;
  }

  &.cyan-btn {
   @include btn-background($cyan-light);
  }

  &.lime-btn {
    @include btn-background($lime);
  }
}


.power-bi-demo-btn {
  font-weight: 700;
  background: none;
  border: none;
  padding: 0 0px 0 12px;
  height: $button-height;
  line-height: $button-height;
  display: inline-block;
  text-decoration: none;
  max-width: 134px;

  &:hover,
  &:focus,
  &:active {
    outline: none;
    cursor: pointer;
    color: $not-black;
  }

  &.cyan-btn {
   @include btn-background($cyan-light);
  }

  &.lime-btn {
    @include btn-background($lime);
  }
}